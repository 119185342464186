@media (max-width: 768px) {

  [class*="col-"] {
      margin-bottom: 1rem;
  }

}

.logo {
  max-height: 80px;

  &.big {
    max-height: 100px;
  }

  @media(max-width: 991px) {
    max-height: 80px;
  }
}

.logo-footer {
  max-height: 60px;
}

section {
  position: relative;
  padding: 5rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }

  &.section-img {
    min-height: 400px;
  }
}

.nav-section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero {
  padding: 8rem 0;
  min-height: 450px;
  display: flex;
  align-items: center;
  text-align: center;
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.25);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.5);
  }

  &.light {
    background-color: rgba(white, 0.67);
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.separator-right-lg {
  @media(min-width: 992px) {
    border-right: 4px solid $primary;
    padding-right: 1.5rem;
  }
}

.bg-hero-intro {
  background: no-repeat center 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-world.jpg);
}

.bg-hero-mission {
  background: no-repeat center bottom 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-mission.jpg);
}

.bg-forest-intro {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest-intro.jpg);
}

.bg-city {
  background: no-repeat center bottom;
  background-size: cover;
  background-image: url(../img/city.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-action {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-action.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-campus {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-elementary.jpg);
}

.bg-forest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest.jpg);
}

.bg-registration {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-registration.jpg);
}

.bg-resources {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-resources.jpg);
}

.bg-nest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-nest-hero.jpg);
}

.bg-middle {
  background: no-repeat 50% 25%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.bg-parents-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/journey-3.jpg);
}

.bg-huddle {
  background: no-repeat center 30%;
  background-size: cover;
  background-image: url(../img/huddle.jpg);
}

.bg-extended {
  background: no-repeat center 20%;
  background-size: cover;
  background-image: url(../img/group.jpg);
}

.bg-bench {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bench.jpg);
}

.bg-tree {
  background: no-repeat center 40%;
  background-size: cover;
  background-image: url(../img/tree.jpg);
}

.bg-yard {
  background: no-repeat center 10%;
  background-size: cover;
  background-image: url(../img/yard.jpg);
}

.testimonial {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 300;

  .content {
    font-weight: 300;
  }

  .attribution {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  &.center { text-align: center; }
  .white { color: white; }

  h3 {
    font-size: 1.75rem;
  }

  p {
    font-weight: 300;
    font-size: 15px;
    line-height: 1.62;
  }
}

.box {
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 9px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.icon {
  max-height: 80px;
  height: 4rem;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: inline-block;
    margin: 1rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }

    @media (max-width: 991px) {
      display: block;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  .social-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    height: 48px;
    width: 48px;
    font-size: 24px;
    text-align: center;
    color: white;
    background: $primary;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $primary;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-wrapper {
  height: 280px;
  width: auto;
  max-width: 100%;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &.vertical {
    height: 400px;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-circle{
  border-radius: 50%;
  width: 100%;
}

.img-featured {
  max-height: 450px;
}

.parents-club-books {
    a {
        font-weight: 700;
        text-decoration: underline;
    }
}

.parents-club-video {
    overflow: hidden;
    iframe {
        background-color: black;
        width: 100%;
        height: 310px;
    }

    p {
        text-align: center;
    }

    &.col-md-4{
        iframe{
            height: 200px;
        }
    }

}

.person-img {
  height: 180px;
  width: 180px;
  object-fit: cover;
}

.tools-images {
  @media (max-width: 991px) {
    img {
      max-height: 80px;
      max-width: 180px;
    }
  }
}

.navigation-box {
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms all;

  .box-header {
    padding: 2rem 30px 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .box-body {
    padding: 1rem 30px;
    p { font-size: 1rem; }
    p:last-child { margin-bottom: 0; }
  }

  .box-footer {
    margin-top: auto;
    padding: 1rem 30px 2rem;
  }

  &:hover, &:focus, &:active {
    transform: translateY(-5px);
  }
}

.location-row {
  [class*=' col-'], .col-md-4 {
    min-height: 400px;
  }

  .box {
    padding: 5rem 30px;
    height: 100%;
  }
}

.learning-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;

  .learning-box {
    display: inline-block;
    vertical-align: top;
    margin: 4px;
    max-width: 400px;
    border-radius: 3px;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .learning-box-body {
      padding: 30px;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 600;
    }

    h5 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      margin-left: 8px;
    }

    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 0;
      border-top: 4px solid white;
      max-width: 30px;
      &.black { border-top-color: $black; }
    }

    .highlight {
      font-size: 1.25rem;
      position: relative;
      text-align: center;
      margin: 1rem 0;
    }

    .underline {
      display: inline-block;
    }

  }
}

.learning-box.horizontal {
  @media (min-width: 992px) {
    width: 100%;
    max-width: 100%;
    display: flex;

    .img-wrapper {
      min-width: 360px;
      max-width: 360px;
      width: 360px;
      height: 100%;
    }
  }
}

.box-event {
  padding: 30px;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 4px;

  .title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .info {
    color: $black;
  }

  .button {
    margin-bottom: 0;
  }
}

.schedule {
  text-align: left;

  .time-slot {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    border: dashed 2px $tertiary;
    margin-bottom: 8px;
    color: $tertiary;

    .time {
      width: 75px;
    }
    .description {
      width: 100%;
    }

    &.solid {
      background-color: $primary;
      color: white;
      border: 2px solid $primary;
    }

    &.red {
      background-color: $tertiary;
      border-color: $tertiary;
      color: white;
    }

    &.bg-light-blue {
      border: 2px solid $secondary;
      background-color: $secondary;
    }
  }
}

.deconstructed-logo {
  width: 100%;

  .part {
    position: relative;
    max-width: 50%;
    margin-left: 0;
    transition: 200ms all;

    img {
      max-width: 100%;
    }

    .arrow {
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
    }

    &:hover {
      cursor: pointer;
      margin-left: 20px;
    }

    &.active {
      margin-left: 50%;

      .arrow {
        display: none;
      }

      .content {
        display: block;
      }

      &:hover { margin-left: calc(50% - 20px);}
    }

    &:first-child { margin-bottom: -14%;}
    &:nth-child(2) { margin-bottom: -10%;}
    &:nth-child(3) { margin-bottom: -5%;}
    &:nth-child(4) { margin-bottom: -3%;}
  }
}

.deconstructed-logo-content {
  display: flex;
  justify-content: flex-end;

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .content {
    max-width: 500px;
    display: none;

    &.active {
      display: flex;
      .icon { margin-right: 2rem; }
    }
  }
}

.school-comparison {
  ul {
    text-align: left;
    list-style-type: none;
    counter-reset: item 0;
    padding-left: 0;
    margin-top: 2rem;
  }

  li {
    padding-left: 2rem;
    padding-right: 10px;
    counter-increment: item 1;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    color: $black;

    .content {
      @media(max-width: 991px) {
        min-height: 120px;
        font-size: 0.8rem;
      }
    }

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      content: counter(item);
      font-weight: 900;
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      border-radius: 50%;
      border: 2px solid $primary;
      font-size: 1.5rem;
      margin-right: 1rem;
      color: white;
      margin-left: -2rem;
      background-color: $primary;
      border-color: transparent;
    }

    &:last-child { margin-bottom: 0; }
  }

  ul.grey {
    li:before {
      background: white;
      color: $light-grey;
      border-color: $light-grey;
    }
  }

  @media(max-width: 991px) {
    h2 {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

.img-cont-16-9{
  width: 100%;
  height: 0;
  box-sizing: content-box;
  position: relative;
  padding-top: 56.25%;

  img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-link .img-cont-16-9{
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba(0,0,0,.3) url(../img/videos/icon-play.png) no-repeat center center;
    background-size: 60px 60px;
    opacity: 0;
    transition: .2s ease-in opacity;
  }
  &:hover{
    &:after{opacity: 1;}
  }
}

.map{
  iframe{
    width: 100%;
    display: block;
  }
}

#skills {
  a.box {
    color: #fff;
    display: block;
    width: 100%;
    min-height: 550px;
    height: auto;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    margin-top: -150px;
  }

  .content-main {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 2;
    height: 3em;
    transition: all .2s ease;
  }

  .content-title::before {
    content: '';
    display: block;
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    background-size: cover;
    margin-bottom: 20px;
  }

  .content-title.content-title-one::before {
    background: url(../img/icon-skill-one.svg) no-repeat;
  }

  .content-title.content-title-two::before {
    background: url(../img/icon-skill-two.svg) no-repeat;
  }

  .content-title.content-title-three::before {
    background: url(../img/icon-skill-three.svg) no-repeat;
  }

  .content-title.content-title-four::before {
    background: url(../img/icon-skill-four.svg) no-repeat;
  }

  a:hover .content-main {
    height: 8em;
    transition: all .2s ease;
  }

  h3 {
    font-size: 2em;
    font-weight: normal;
    margin: 0 0 5px 0;
  }

  .description {
    height: 0;
    opacity: 0;
    visibility: hidden;
    margin: 5px;
    line-height: 1.5em;
    margin-top: 10px;
    color: $black;
    @include serif;
  }

  a:hover .description{
    height: 3em;
    opacity: 1;
    visibility: visible;
    transition: all .2s ease;
  }

  .link {
    height: 1.5em;
    line-height: 1.5;
    transition: all .2s ease;
    display: block;
    font-size: 0.85em;
    font-style: italic;
  }

  a:hover .link {
    transition: all .2s ease;
  }
}

.highlight {
  padding: 0 .5rem;
  vertical-align: middle;
  background-color: #fff;
  background: linear-gradient(90deg,rgba($primary, 0.7) 0,rgba($primary, 0.7) 100%);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  border-radius: 1em 0 1em 0;
  color: $secondary;
}

.icon-wrapper {
  height: 80px;
  width: 80px;
  img { object-fit: contain; }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 4px solid white;
  max-width: 30px;
  &.black { border-top-color: $primary; }
}

.featured-box {
  position: relative;
  display: block;
  border-radius: 6px;
  padding: 48px 30px;
  background-image: url("/img/bg-hero-world.jpg");
  text-decoration: none;
  color: inherit;
  outline: none;
  background-color: white;
  background: no-repeat center bottom 30%;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  transition: 200ms all ease-in-out;

  &:hover, &:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
    transform: scale(1.067);
  }
}

.embed-responsive {
  border-radius: 6px;
  overflow: hidden;
  transition: 200ms all ease-in-out;

  &:hover, &:focus, &:active {
    transform: scale(1.05);
  }
}

.squiggle {
  max-width: 180px;
  margin: -30px auto;
  display: block;
  transform: rotate(22deg);
}

.img-testimonial {
  width: 220px;
  height: 220px;
}

.img-video-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 3rem;
  transition: 200ms all;
  box-shadow: none;
  cursor: pointer;

  .play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -29px;
    margin-top: -29px;
    transition: 200ms all;
  }

  &:hover {
    transform: translateY(-2px);
    .play {
      transform: scale(1.05);
    }
  }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: $tertiary;
}

.bubble {
  color: $secondary;
  background-color: $primary;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
}

.countdowns-wrapper {
  padding: 24px 8px;
  display: flex;
}

.countdowns {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  max-width: 540px;
  margin: auto;

  .countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.8rem;
    color: white;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 1;
      border: 2px solid white;
      border-radius: 3px;
      margin-bottom: 6px;
      padding: 1rem;
    }
  }
} 
.item {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
.home-title {
  font-size: 4rem;
  text-shadow: 0px 1px 1px #ffffff94;
}
.img-steps{
  max-width: 40px;
  max-height: 40px;
}
.index-steps{
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  margin-bottom: 36px;
  .lead-sm{
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 18px;
    padding: 8px 16px;
    background: #0a3746;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;
    line-height: 1.3rem;
  }
}
.horizontal-list {
  display: flex;
  justify-content: left;
  align-items: start;
  row-gap: 0;
  flex-wrap: wrap;
  column-gap: 0px;
  font-size: 17px;
  flex-wrap: wrap;
  flex-direction: column;
  li {
    margin: 0.65rem 0 !important;
  }
}
.index-steps-wrapper {
  display: flex;
  background: #196077;
  padding: 60px 0;
  border-radius: 4px;
}
.img-steps-wrapper {
  background: #ffc008;
  padding: 26px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #134e60;
  position: relative;
}
h6.number-audition {
  position: absolute;
  background: #0a3745;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -2px;
  border-radius: 100%;
  font-size: 1.2rem;
  font-weight: 900;
}

@media(min-width: 768px) {
  .border-sides {
    border-left: 1px solid #4c8394;
    border-right: 1px solid #4c8394;
  }
}
.icon-column{
  display: flex !important;
  background-color: red;
  height: 80px;
  width: 80px;
  min-width: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  color: white;
  font-size: 2rem;
}
.icon-cont-home{
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2rem;
}
.bg-home {
  background: 
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url(../img/bg_home.jpeg) center center;
  background-size: cover;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
  .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
}

.modal.fade .modal-dialog {
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  transform: none;
}
.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}

.value-box {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 200ms ease-in-out;
  border: 1px solid rgba(0,0,0,0.075);

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    position: relative;
    padding-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 900;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 4px;
      background: $primary;
      border-radius: 2px;
    }
  }

  p {
    color: $black;
    font-weight: 300;
    line-height: 1.72;
    font-size: 16px;
    margin: 0;
  }
}

@media (max-width: 991px) {
  .values-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
.button-sm {
  font-size: 0.8rem !important;
  padding: 0.5rem 1rem !important;
}
button.close {
  padding: 20px 30px;
  position: absolute;
  right: 0;
  z-index: 1000;
}
.question-wrapper.nested {
  margin-top: 1rem;
  border: none;
  background-color: #f8f9fa;
  padding: 1.25rem;
  border-radius: 8px;

  .question {
    font-size: 1.15em !important;
    
    &::after {
      background-image: url(../img/icon-chevron-down.png) !important;
    }
  }

  .answer {
    font-size: 0.9em !important;
  }

  &.active .question::after {
    background-image: url(../img/icon-chevron-up.png) !important;
  }
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;

    i {
      color: white;
      font-size: 2rem;
      opacity: 0.8;
      transition: opacity 0.3s ease;
    }
  }

  &:hover .play-overlay {
    background: rgba(0,0,0,0.5);

    i {
      opacity: 1;
    }
  }
}

.video-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 10001;
  padding: 20px;

  &-content {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
  }

  &-close {
    position: absolute;
    top: -40px;
    right: 0;
    color: white;
    font-size: 2rem;
    cursor: pointer;
  }

  iframe {
    width: 100%;
    height: 450px;
    border: none;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .video-thumbnail {
    .play-overlay i {
      font-size: 1.5rem;
    }
  }
}