.books-component {
  display: flex;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    max-width: 40%;
    flex-shrink: 0;
  }

  .books {
    display: flex;
    position: relative;
    width: 100%;
    padding-left: 5rem;
    height: 640px;
  }

  .book {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 480px;
    left: 100%;
    bottom: 0;
    transition: 200ms ease-in-out all;

    .book-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 86px;
      padding: 1.5rem 1rem;
      border-right: 2px solid rgba(white, 0.2);
      cursor: pointer;
    }

    .book-number {
      opacity: 0.5;
    }

    .book-title {
      transform: rotate(-90deg);
      white-space: nowrap;
      opacity: 0.5;
    }

    .book-arrow {
      display: flex;
      justify-content: center;
      opacity: 0.25;
      transform: rotate(180deg);
      transition: 200ms ease-in-out all;
    }

    .book-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      width: 100%;

      .book-body-content {
        max-width: 320px;
      }
    }

    &:hover {
      transform: translateX(calc(-10px));
    }

    &.open {
      transform: translateX(calc(-100% + 86px));

      .book-arrow { transform: rotate(0deg); }
    }
  }

  .book:nth-child(1) { left: calc(100% - (86px * 3)); height: calc(100% - 60px) }
  .book:nth-child(2) { left: calc(100% - (86px * 2)); height: calc(100% - 30px) }
  .book:nth-child(3) { left: calc(100% - 86px); height: calc(100%) }

  @media(max-width: 991px) {
    flex-direction: column;

    .content {
      padding: 0 3rem;
      max-width: 100%;
      margin-bottom: 2rem;
    }

    .book {
      max-width: calc(100% - 172px);
    }
  }
}
