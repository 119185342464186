@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: "DM Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
  margin: 0;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: $black;
  margin-top: 114px;
}

.sticky-top {
  top: 120px !important;
}

@mixin serif {
  font-family: "DM Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
}

@mixin sans-serif {
  font-family: "DM Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
  line-height: 1.2;
  letter-spacing: -0.025em;
  font-weight: 400;
  color: #292929;
  @include serif;
}

.section-heading, .section-title {
  font-size: 3rem;
  font-weight: 600;
  @include serif;

  @media (max-width: 991px) { font-size: 2.5rem; }
}

.serif {
  @include serif;
}

.sans-serif {
  @include sans-serif;
}

h1, .h1 {
  font-size: 2.75rem;
  line-height: 1.1;

  @media(max-width: 991px) { font-size: 2.25rem; }
}

h2, .h2 {
  font-size: 2.75rem;

  @media(max-width: 991px) { font-size: 2.25rem; }
}

h3, .h3 {
  font-size: 2.5rem;

  @media(max-width: 991px) { font-size: 2rem; }
}

h4, .h4 {
  font-size: 2rem;

  @media(max-width: 991px) { font-size: 1.5rem; }
}

h5, .h5 {
  font-size: 1.75rem;

  @media(max-width: 991px) { font-size: 1.15rem; }
}

a {
  color: $primary;
  font-weight: 500;
  transition: 200ms all;

  &:hover {
    color: darken($secondary, 10%);
  }
}

.uppercase {
  text-transform: uppercase;
}

.hide, .hidden {
  display: none;
}

.center, .centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold, strong {
  font-weight: bold;
}

.lead {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 300;

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.lead-sm {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 300;
}
.lead-sm-faq {
  font-size: 1.2rem !important;
  line-height: 1.7;
  font-weight: 500 !important;
}

.light {
  font-weight: 300;
}

.no-effects {
  &:hover, &:focus, &:active { outline: none; }
}

.white-letters {
  color: white;
  h1, h2, h3, h4, h5, h6, p, a, ul, li {
    color: white;
  }

  .styled-number-list li:before {
    color: white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.bring-to-front {
  position: relative;
  z-index: 100;
}

.hidden-mobile {
  @media(max-width: 991px) {
    display: none;
  }
}

.centered-mobile {
  @media(max-width: 991px) {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}

.link-absolute {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.separator-bottom { margin-bottom: 3rem; }
.separator-top { margin-top: 3rem; }

.visible-sm {
  @media(min-width: 992px) {
    display: none;
  }
}

.img-size-std {
  max-height: 500px;
}

.underline {
  text-decoration: underline;
}
.openhoouse-title{
  font-family: 'Montserrat';
  color: #d0a72f;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem !important;
}
.openhoouse-subtitle{
  font-family: 'Montserrat';
  color: #216177;
  font-size: 1.5rem;
  margin-bottom: 1rem !important;
  font-weight: 700;
}
.openhouse-address{
  font-family: 'Montserrat';
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 400;
}
.openhouse-container{
  max-width: 550px;
  margin: auto auto;
}
.openhouse-h6{
  background-color: #d0a72f;
  margin-bottom: 40px;
  padding: 1rem;
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #FFF;
}
.pb-sticky-bar--content{
  position: absolute;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -100%;
  width: 100%;
  animation: roll 24s linear infinite;
}
.pb-sticky-bar{
  position: relative;
  left: 0;
  right: 0;
  height: 40px;
}
@keyframes roll {
  from {
    left: -50%;
  }
  to {
    left: 75%;
  }
}
.girls-banner{
  background: $yellow no-repeat right;
  background-size: contain;
  @media(max-width: 1200px) { background-position-x: 488px; }
  @media(max-width: 991px) { background-position-x: 388px; }
  @media(max-width: 680px) { background-position-x: 237px; background-size: 130%; }
}
.banner-title-bg{
  background: $secondary;
  display: inline-flex;
  padding: 12px 24px;
}
.button.button-banner{
  background-color: $secondary !important;
  color: white !important;
  border: none;
}
.open-public{
  font-size: 29px;
  color: #cb3d82 !important;
}
.section-padding{
  padding-top: 100px;
  padding-bottom: 100px;
}